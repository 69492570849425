import {Route, Routes} from "react-router-dom";
import External from './pages/layout/External';
import Internal from "./pages/layout/Internal";

// External Pages
import Home from "./pages/Home";
import Login from "./pages/auth/Login";
import Recovery from "./pages/auth/Recovery";

import NotFound from "./pages/NotFound";

// Internal Pages
import Dashboard from "./pages/Dashboard";
import Immediate from "./pages/appointment/Immediate";
import WaitingRoom from "./pages/appointment/WaitingRoom";
import History from "./pages/appointment/History";
import Profile from "./pages/account/Profile";
import PersonalForm from "./pages/account/PersonalForm";
import CredentialForm from "./pages/account/CredentialForm";

import {useAuth} from "./hooks/useAuth";

const AppRoutes = () => {
    const {user} = useAuth();

    return (
        <Routes>
            {
                user
                    ? (
                        <Route path={"/"} element={<Internal/>}>
                            <Route index element={<Dashboard/>}/>
                            <Route path={'atendimento/pa'} element={<Immediate/>}/>
                            <Route path={"atendimento/sala-de-espera/:appointmentId"} element={<WaitingRoom/>}/>
                            <Route path={"atendimento/historico"} element={<History/>}/>
                            <Route path={'conta/perfil/'} element={<Profile/>}>
                                <Route index element={<PersonalForm/>}/>
                                <Route path={'dados-cadastrais'} element={<PersonalForm/>}/>
                                <Route path={'credenciais'} element={<CredentialForm/>}/>
                            </Route>
                        </Route>
                    )
                    : (
                        <Route path={"/"} element={<External/>}>
                            <Route index element={<Home/>}/>
                            <Route path={'login'} element={<Login/>}/>
                            <Route path={"recuperar"} element={<Recovery/>}/>
                        </Route>
                    )
            }
            <Route path={"*"} element={<NotFound/>}/>
        </Routes>
    );
};

export default AppRoutes;
