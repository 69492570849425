import {Stack} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import {useNavigate} from 'react-router-dom';
import api from '../../helpers/api';
import React, {useEffect, useState} from 'react';
import figWaiting from '../../assets/images/fig-waiting.png';
import figVideoDoctor from '../../assets/images/fig-video-doctor.png';

import {useParams} from 'react-router-dom';
import {useToast} from "../../hooks/useToast";

const WaitingRoom = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const {appointmentId} = useParams();
    const [appointmentData, setAppointmentData] = useState([]);

    const fetchAppointment = async () => {
        try {
            const resp = await api.get(`video/atendimento/${appointmentId}`);
            const {data} = {...resp.data};

            if (!data || ![0, 1, 2].includes(data.status)) {
                navigate('/atendimento/historico');
                return;
            }

            setAppointmentData(data);
        } catch (e) {
            if (e.response && e.response.status >= 400 && e.response.status < 500) {
                toast.showToast(e.response.data.errors[0], 'danger');
            }
        }
    };

    const cancelAppointment = async () => {
        try {
            const {Id} = {...appointmentData};
            await api.delete(`video/atendimento/${Id}`);
            toast.showToast('Atendimento Cancelado', 'warning');
            await fetchAppointment();
        } catch (e) {
            if(e.response && e.response.status >= 400){
                const {errors} = {...e.response.data};
                toast.showToast(`Não foi possível cancelar o atendimento. "${errors[0]}"`, 'danger');
            }
        }
    };

    const startAppointment = async () => {
        window.open(appointmentData.callURL, '_blank', 'width=600,height=600,toolbar=no,menubar=no,location=no,status=no,scrollbars=yes');
        navigate('/');
    };

    useEffect(() => {
        fetchAppointment().then();
    },[]);

    return (
        <>
            <h1 className="h2">Sala de Espera</h1>
            <Row className="justify-content-center">
                <Col xs={12} md={4}>
                    {[0, 1].includes(appointmentData.status) && (
                        <Stack gap={3}>
                            <Image src={figWaiting} className="img-fluid" alt="Imagem de pergunta"/>
                            <p className="small mb-0">Se por algum motivo você não puder comparecer em seu atendimento,
                                por favor nos informe pressionando o botão abaixo.</p>
                            <Button variant="danger" onClick={cancelAppointment}>CANCELAR ATENDIMENTO</Button>
                        </Stack>
                    )}

                    {appointmentData.status === 2 && (
                        <Stack gap={3}>
                            <Image src={figVideoDoctor} className="img-fluid" alt="Imagem de pergunta"/>
                            <p className="small mb-0">O profissional que irá te atender já se encontra na sala.
                                Pressione o botão abaixo para entrar!</p>
                            <Button variant="primary" onClick={startAppointment}>ENTRAR NO ATENDIMENTO</Button>
                        </Stack>
                    )}
                </Col>
            </Row>
        </>
    );
}

export default WaitingRoom;